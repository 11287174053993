<template>
	<div class="box">
		<div class="row">
			<div class="col-12">
				<div class="form-group">
					<label for="sire">{{ $t('import.numero_sire') }}</label>
					<textarea id="sire" class="form-control" rows="10" v-model="sire"></textarea>
				</div>

                <div class="text-center">
                	<b-button variant="primary" class="rounded-pill" @click="importSire">
                       <font-awesome-icon v-if="loading" :icon="['fal', 'spinner']" spin /><font-awesome-icon v-else :icon="['far', 'plus']" /> {{ $t('import.importer') }}
                    </b-button>
                </div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
import Horse from "@/mixins/Horse.js"
import Common from '@/assets/js/common.js'

export default {
	name: 'ImportHorse',
	mixins: [Horse],
	data () {
		return {
			sire: "",
			loading: false
		}
	},

	methods: {
		async importSire() {
			this.loading = true

			// je récupère les SIRE séparés par des retours à la ligne puis je supprime les lignes vides
			let sire_tab = this.sire.split('\n')
			sire_tab = sire_tab.filter(sire => (!!sire))

			await Common.asyncForEach(sire_tab, async (sire) => {
				await this.addHorseBySire(sire)
			})

			this.sire = ''
			this.loading = false
			this.successToast("toast.info_modif_succes")
		},
	}
}

</script>